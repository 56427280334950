<mat-card>
    <mat-card-title style="font-size: 32px; font-family: Noto Sans, sans-serif !important;">
      <mat-icon class="customIconSize material-icons-outlined">arrow_forward</mat-icon>
      Reportes
    </mat-card-title>
    <!-- <mat-divider></mat-divider> -->
    <div class="row">
      <div class="col-lg-12">
        <!-- <mat-tab-group animationDuration="1000ms">
          <mat-tab label="PRESENCIAL"> -->
            <!-- <mat-card-content style="text-align: center; padding: 40px 0 0 0;"> -->
            <div style="text-align: center; padding: 40px 0 40px 0;">
              <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="getCDF()">
                <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
                CDF Supervisión 
                <!-- de Presencial -->
              </button>
            </div>
            <!-- </mat-card-content>
            <mat-card-content style="text-align: center; padding: 40px 0 0 0;">
              <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="getRESProtocolo()">
                <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
                Resumen Estado Supervisión de Presencial
              </button>
            </mat-card-content>
          </mat-tab>
          <mat-tab label="TELEFÓNICO">
            <mat-card-content style="text-align: center; padding: 40px 0 0 0;">
              <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="getCDFValidez()">
                <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
                CDF Supervisión de Telefónica
              </button>
            </mat-card-content>
            <mat-card-content style="text-align: center; padding: 40px 0 0 0;">
              <button mat-flat-button color="primary" style="padding: 0 30px 0 10px;" (click)="getRESValidez()">
                <mat-icon class="material-icons-two-tone" style="padding: 0 10px 0 0;">description</mat-icon>
                Resumen estado Supervisión de Telefónica
              </button>
            </mat-card-content>
          </mat-tab>
        </mat-tab-group> -->
      </div>
    </div>
  </mat-card>