import { Component, OnInit } from '@angular/core';
import { IndicadoresServices } from 'src/app/core/service/indicadores.service';
import { TypesServices } from 'src/app/core/service/types.service';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Download } from 'src/app/core/service/download.service';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

@Component({
    selector: 'app-reportes',
    templateUrl: './reportes.component.html',
    styleUrls: ['./reportes.component.scss']
  })

  export class ReportesComponent implements OnInit{

    urlcdval:string;
    urlcdfprot:string;
    urlresval:string;
    urlresprot:string;
    constructor(
      private download: Download
    ) {
      
    }

    ngOnInit() {
      // this.getCDFProtocolo();
      // this.getCDFValidez();
      // this.getRESProtocolo();
      // this.getRESValidez();
    }


    getCDF(){
      this.download.getCDF().then( e =>{
        const {data} = e;
        if ( parseInt(data.status) === 200 ) {
          this.urlcdfprot = e.data.url;
          window.open(this.urlcdfprot, '_blank');
          Toast.fire({
            icon: 'success',
            title: 'Archivo descargado correctamente.'
          });
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Inconvenientes al descargar el reporte.'
          });
        }
        // console.log(e.data.urlcdfprot);
      })
    }
    getCDFValidez(){
      this.download.getCDFValidez().then(e=>{
        const {data} = e;
        if ( parseInt(data.status) === 200 ) {
          this.urlcdfprot = e.data.url;
          window.open(this.urlcdfprot, '_blank');
          Toast.fire({
            icon: 'success',
            title: 'Archivo descargado correctamente.'
          });
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Inconvenientes al descargar el reporte.'
          });
        }
      })
    }
    getRESProtocolo(){
      this.download.getRESProtocolo().then(e=>{
        const {data} = e;
        if ( parseInt(data.status) === 200 ) {
          this.urlcdfprot = e.data.url;
          window.open(this.urlcdfprot, '_blank');
          Toast.fire({
            icon: 'success',
            title: 'Archivo descargado correctamente.'
          });
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Inconvenientes al descargar el reporte.'
          });
        }
      })
    }
    getRESValidez(){
      this.download.getRESValidez().then(e=>{
        const {data} = e;
        if ( parseInt(data.status) === 200 ) {
          this.urlcdfprot = e.data.url;
          window.open(this.urlcdfprot, '_blank');
          Toast.fire({
            icon: 'success',
            title: 'Archivo descargado correctamente.'
          });
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Inconvenientes al descargar el reporte.'
          });
        }
      })
    }
  }

  