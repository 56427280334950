<mat-toolbar color="primary" class="fix-nav">
  <button type="button" mat-icon-button class="visible-md" (click)="toggleSidebar()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div class="nav-brand">
    Seguimiento ELPI 2024
  </div>
  <span class="nav-spacer"></span>
  <button mat-icon-button (click)="onLoggedout()">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>
