import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { Subject, BehaviorSubject, Observable } from 'rxjs';
// import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class  Download {

    constructor(private http: HttpClient) { }

    //Api para obtener las regiones que tengan encuestas asignadas o todas
    // getDownload( ) {
    //     return this.http
    //         .post<any>(`${environment.apiUrl}/api/v1/download/boton-uno`,{})
    //         .pipe(map(data => data)).toPromise();
    // }

    getCDF( ) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/v1/download/cdf-supervision`,{})
            .pipe(map(data => data)).toPromise();
    }

    getCDFValidez( ) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/download/cdf-supervision-validez`,{})
            .pipe(map(data => data)).toPromise();
    }

    getRESProtocolo( ) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/download/res-supervision-protocolo`,{})
            .pipe(map(data => data)).toPromise();
    }

    getRESValidez( ) {
        return this.http
            .post<any>(`${environment.apiUrl}/api/download/res-supervision-validez`,{})
            .pipe(map(data => data)).toPromise();
    }

}