<div class="login-page">
  <div class="content px20">
    <form class="login-form" #myForm="ngForm" [formGroup]="formLogin" (ngSubmit)="onLogin()">
      <div class="text-center">
        <h2 class="app-name">
          <img src="./assets/images/elpi2024.png"/>
        </h2>
      </div>
      <div fxLayout="row">
        <div fxFlexFill >
          <mat-form-field class="w-100" appearance="fill">
            <input 
              matInput 
              formControlName="username"
              placeholder="Usuario"
              required
            >
          </mat-form-field>
          <mat-form-field class="w-100">
            <input 
              matInput
              formControlName="password"
              type="password" 
              placeholder="Contraseña"
              required
            >
          </mat-form-field>
        </div>
      </div>
      <br>
      <div fxLayout="row">
        <div fxFlexFill>
          <button type="submit" mat-raised-button color="primary" class="w-100">Login</button>
        </div>
      </div>
    </form>
  </div>
</div>
